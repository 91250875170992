<template>
	<div class="h-100 dis-flex-com">
		<div class="flx-1 dis-flex-com login">
			<div v-if="companyListStatus == 1">
				<div class="pd-b-15 ft-sz-16">
					<div class="">
						您好，
					</div>
					<div class="">
						请绑定您的手机号
					</div>
				</div>
				<div class="pd-t-30">
					<div class="dis-flex-row al-c pd-b-15 bd-bott">
						<div class="mg-r-10">
							<van-icon name="phone-o" />
						</div>
						<input class="flx-1 mobile-fd" type="number" v-model="zhDl.usermobile" placeholder="+86" />
					</div>
					<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
						<div class="pd-r-15 dis-flex-row al-c loginCode-class">
							<div class="mg-r-10">
								<van-icon  name="comment-o" />
							</div>
							<input class="flx-1" v-model="zhDl.loginCode" placeholder="请输入验证码" />
						</div>
						<div :class="!canClick?'bk-co-66':'bk-co-fd'" class="pd-x-10 pd-t-5 pd-b-5 ft-co-ff mg-l-auto" @click="countDown(1)">
							{{codecontent}}
						</div>
					</div>
				</div>
				<div class="pd-t-30 mg-t-30">
					<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="bindSubmin">立即邦定</button>
				</div>
			</div>
			<div v-if="companyListStatus == 2">
				<CompanyList :company_list="company_list"></CompanyList>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.login {
		padding: 60px 48px 0 48px;
		left: 0;
		top: 0;
		z-index: 2;
	}
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
</style>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import CompanyList from '../../components/companyList.vue';
	import { Toast } from 'vant';
	var {adminGeneral} = http
	export default {
		name: 'Home',
		components: {CompanyList},
		data() {
			return {
				util:util,
				zhDl: {
					usermobile: '',
					loginCode: '',
				},
				codecontent: '发送验证码',
				totalTime: 60,
				canClick: true,
				companyListStatus:0,
				company_list:[],
				url:''
			}
		},
		created(){},
		mounted() {
			let s = this;
			s.url = window.location.href;
			s.$store.commit('setToken',{token:s.parem('token'),userid:s.parem('userid')})
			s.wx_response();
		},
		activated() {},
		methods: {
			wx_response(){/*用户握手*/
			let s = this;
				adminGeneral.shakeHands.http({}).then(res => {
					if(res.getret === 0){
						s.company_list = res.info.company_list
						if( res.info.usermobile == ''){
							s.companyListStatus = 1;
						}else if(res.info.company_list.length == 0){
							s.$store.commit('setCompanyid','')
							s.$router.push('/')
						}else if(res.info.company_list.length == 1){
							s.$store.commit('setCompanyid',res.info.company_list[0].companyid)
							s.$router.push('/')
						}else{
							s.companyListStatus = 2;
						}
					}
				})
			},
			bindSubmin(){
				let s = this;
				if(s.zhDl.loginCode == '' || s.zhDl.loginCode.length != 6) return Toast('验证码不正确');
				adminGeneral.setuserInfo.http({
					info:{
					"usermobile":s.zhDl.usermobile,
				}}).then(res => {
					Toast(res.msg)
					if(res.getret === 0){
						s.companyListStatus = 0;
						if(s.company_list.length == 0){
							s.$store.commit('setCompanyid','')
							s.$router.push('/')
						}else if(s.company_list.length == 1){
							s.$store.commit('setCompanyid',s.company_list[0].companyid)
							s.$router.push('/')
						}else{
							s.companyListStatus = 2;
						}
					}
				})
			},
			switchCompanyList(){
				let s = this;
				s.$router.push('/')
			},
			countDown(type) { //发送验证码
				let s = this;
				if(s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
				if(!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
				if(!s.canClick) return false;
				s.util.countDown(this,type)
			},
			parem(val){
				let s = this;
				var key
				s.util.getCaption(s.url,'?').split("&").forEach(item=>{
					var inx = item.indexOf('=');
					if(item.substring(0,inx) == val){
						key =  item.substring(inx+1,item.length)
					}
				})
				return key;
			}
		},
		destroyed() {}
	}
</script>
