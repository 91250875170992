<template>
	<div class="h-100 dis-flex-com po-r">
		<img class="po-a w-100 h-100" src="../../../assets/image/login-bg.jpg">
		<div class="po-a w-100 h-100 ">
			<div class="flx-1 dis-flex-com login">
				<div class="ft-sz-22">
					<div class="">
						您好，
					</div>
					<div class="">
						欢迎<span style="color: red;">注册</span>智小媒
					</div>
				</div>
				<div class="dis-flex-row al-c pd-b-15 bd-bott pd-t-30">
					<div class="mg-r-10">
						<van-icon name="phone-o" />
					</div>
					<input class="flx-1 mobile-fd" type="number" v-model="zhDl.usermobile" placeholder="+86" />
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="pd-r-15 dis-flex-row al-c loginCode-class">
						<div class="mg-r-10">
							<van-icon name="comment-o" />
						</div>
						<input class="flx-1" v-model="zhDl.registeredCode" placeholder="请输入验证码" />
					</div>
					<div :class="!canClick?'bk-co-66':'bk-co-fd'" class="pd-x-10 pd-t-5 pd-b-5 ft-co-ff mg-l-auto" @click="countDown(2)">
						{{codecontent}}
					</div>
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="mg-r-10">
						<van-icon  name="eye-o" />
					</div>
					<input class="flx-1" type="text" v-model="zhDl.registeredName" placeholder="请输入您的真实姓名" />
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="mg-r-10">
						<van-icon name="eye-o" />
					</div>
					<input class="flx-1" type="password" v-model="zhDl.registeredPswd" placeholder="请输入密码" />
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="mg-r-10">
						<van-icon name="eye-o" />
					</div>
					<input class="flx-1" type="password" v-model="zhDl.registeredPswd1" placeholder="请再次输入密码" />
				</div>

				<div class="pd-t-30 mg-t-30">
					<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="submintForm">立即注册</button>
				</div>

				<div class="pd-t-15 dis-flex-row ju-c">
					<div class="dis-flex-row al-c">
						<div class="">
							已有账号?
						</div>
						<div class="" style="color: red;" @click="pushGo">
							去登陆>>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import {
		Toast
	} from 'vant';
	var md5 = require('md5');
	var {
		adminGeneral
	} = http
	export default {
		name: 'Statistical',
		components: {},
		data() {
			return {
				zhDl: {
					usermobile: '',
					registeredCode: '',
					registeredName: '',
					registeredPswd: '',
					registeredPswd1: ''
				},
				codecontent: '发送验证码',
				totalTime: 60,
				canClick: true
			}
		},
		mounted() {

		},
		methods: {
			pushGo() { /*返回*/
				this.$router.go(-1);
			},
			submintForm() {
				let s = this;
				if (s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
				if (!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
				if (s.zhDl.registeredCode.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('验证码不能为空');
				if (s.zhDl.registeredName.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('验证码不能为空');
				if (s.zhDl.registeredPswd.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('密码不能为空');
				if (s.zhDl.registeredPswd1 != s.zhDl.registeredPswd1) return Toast('两次密码不一致');
				if(util.getCaption(window.location.href, '?').indexOf('registcode=') != -1){
					adminGeneral.registeredCy.http({
						"info": {
							"username":s.zhDl.usermobile,
							"usermobile": s.zhDl.usermobile,
							"realname": s.zhDl.registeredName,
							"userpwd": md5(s.zhDl.registeredPswd),
							"code": s.zhDl.registeredCode,
							registcode: util.getCaption(window.location.href, '?').substring(util.getCaption(window.location.href, '?').indexOf("=")+1)
						}
					}).then(res => {
						if (res.getret === 0) {
							Toast(res.msg)
							setTimeout(function() {
								s.$router.go(-1);
							}, 200)
						} else {
							Toast(res.msg)
						}
					})
				}else{
					adminGeneral.registered.http({
						"info": {
							"username":s.zhDl.usermobile,
							"usermobile": s.zhDl.usermobile,
							"realname": s.zhDl.registeredName,
							"userpwd": md5(s.zhDl.registeredPswd),
							"code": s.zhDl.registeredCode
						}
					}).then(res => {
						if (res.getret === 0) {
							Toast(res.msg)
							setTimeout(function() {
								s.$router.go(-1);
							}, 200)
						} else {
							Toast(res.msg)
						}
					})
				}
			},
			countDown(type) { //发送验证码
				let s = this;
				if (s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
				if (!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
				util.countDown(this, type)
			}
		}
	}
</script>
<style scoped>
	.login {
		padding: 60px 48px 0 48px;
		left: 0;
		top: 0;
		z-index: 2;
	}
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
</style>
