<template>
	<div class="h-100 dis-flex-com">
		<img class="po-a w-100 h-100" src="../../../assets/image/login-bg.jpg">
		<div class="po-a w-100 h-100 ">
			<div v-if="!companyListStatus" class="flx-1 dis-flex-com login">
				<div class="ft-sz-22" v-if="tabInx == 1 || tabInx == 2 || tabInx == 4">
					<div class="">
						您好，
					</div>
					<div class="">
						欢迎<span v-if="tabInx != 4">来到</span><span v-else style="color: red;">注册</span>智小媒
					</div>
				</div>
				<div class="pd-t-15 dis-flex-row ju-c ft-sz-14" v-if="tabInx == 1 || tabInx == 2">
					<div class="dis-flex-row al-c pd-t-15">
						<div @click="tabClick(1)" :style="tabInx == 1 ?'color:#999999;':''">
							账号密码登录
						</div>
						<div class="pd-x-15">
							<div style="width: 2px;height: 20px;background-color: #F5F5F5;"></div>
						</div>
						<div @click="tabClick(2)" :style="tabInx == 2 ?'color:#999999;':''">
							验证码登录
						</div>
					</div>
				</div>
				<div class="pd-t-30" v-if="tabInx == 1">
					<div class="dis-flex-row al-c pd-b-15 bd-bott">
						<div class="mg-r-10">
							<van-icon name="friends-o" />
						</div>
						<input class="flx-1 mobile-fd" type="text" v-model="zhDl.nakeName" placeholder="请输入用户名" />
					</div>

					<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
						<div class="mg-r-10">
							<van-icon name="eye-o" />
						</div>
						<input class="flx-1" type="password" v-model="zhDl.password" placeholder="请输入密码" />
					</div>

					<div class="pd-t-30 mg-t-30">
						<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="submintForm">登录</button>
					</div>

					<div class="pd-t-15">
						<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="wxSubmintForm">微信授权一键登陆</button>
					</div>


					<div class="pd-t-15 dis-flex-row al-c" style="color: #999999;">
						<div class="mg-l-auto" @click="tabClick(4)">
							注册
						</div>
						<div class="mg-l-15" @click="tabClick(3)">
							忘记密码?
						</div>
					</div>
				</div>
				<div class="pd-t-30" v-if="tabInx == 2">
					<div class="dis-flex-row al-c pd-b-15 bd-bott">
						<div class="mg-r-10">
							<van-icon name="phone-o" />
						</div>
						<input class="flx-1 mobile-fd" type="number" v-model="zhDl.usermobile" placeholder="+86" />
					</div>

					<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
						<div class="pd-r-15 dis-flex-row al-c loginCode-class">
							<div class="mg-r-10">
								<van-icon  name="comment-o" />
							</div>
							<input class="flx-1" v-model="zhDl.loginCode" placeholder="请输入验证码" />
						</div>
						<div :class="!canClick?'bk-co-66':'bk-co-fd'" class="pd-x-10 pd-t-5 pd-b-5 ft-co-ff mg-l-auto" @click="countDown(0)">
							{{codecontent}}
						</div>
					</div>
					<div class="pd-t-30 mg-t-30">
						<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="codeSubmint">登录</button>
					</div>
				</div>
			</div>
			<div v-if="companyListStatus" class="flx-1 dis-flex-com login">
				<CompanyList :company_list="company_list"></CompanyList>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import CompanyList from '../../components/companyList.vue';
	import { Toast } from 'vant';
	var md5 = require('md5');
	var {adminGeneral} = http
	export default {
		name: 'Login',
		components: {CompanyList},
		data() {
			return {
				tabInx: 1,
				zhDl: {
					nakeName: '',
					password: '',
					usermobile: '',
					loginCode: '',
				},
				codecontent: '发送验证码',
				totalTime: 60,
				canClick: true,
				company_list:[],/*公司列表*/
				companyListStatus:false
			}
		},
		mounted() {
			
		},
		methods: {
			tabClick(val) {
				if (val == '3') {
					this.$router.push('/modify/1')
				} else if (val == '4') {
					if(util.getCaption(window.location.href, '?').indexOf('registcode=') != -1){/*是否带公司邀请码*/
						this.$router.push('/registered?'+ util.getCaption(window.location.href, '?'))
					}else{
						this.$router.push('/registered')
					}
				} else {
					this.tabInx = val;
				}
			},
			submintForm() {/*登录*/
				let s = this;
				if(s.zhDl.nakeName.replace(/(^\s*)|(\s*$)/g, "") == "" || s.zhDl.password.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('账号密码不能为空');
				adminGeneral.login.http({
					"username": s.zhDl.nakeName,
					"password": md5(s.zhDl.password),
					"type": 2
				}).then(res => {
					if(res.getret === 0){
						s.succerLogin(res);
					}else{
						Toast(res.msg)
					}
				})
			},
			codeSubmint(){/*验证码登录*/
				let s = this;
				if(s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
				if(!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
				if(s.zhDl.loginCode.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('验证码不能为空');
				adminGeneral.codeLogin.http({
					"mobile": s.zhDl.usermobile,
					"code": s.zhDl.loginCode,
					"type": 2
				}).then(res => {
					if(res.getret === 0){
						s.succerLogin(res);
					}else{
						Toast(res.msg)
					}
				})
			},
			succerLogin(res){/*登录成功统一处理*/
				let s = this;
				s.$store.commit('setToken',{token:res.ui.token,userid:res.ui.userid})
				s.company_list = res.info.company_list
				if(res.info.company_list.length == 0){
					if(util.getCaption(window.location.href, '?').indexOf('registcode=') != -1){/*是否带公司邀请码*/
						s.getCompany()
					}else{
						s.$store.commit('setCompanyid','')
						s.$router.push('/')
						// Toast('等待审核通过方可进入')
					}
				}else if(res.info.company_list.length == 1){
					s.$store.commit('setCompanyid',res.info.company_list[0].companyid)
					s.$router.push('/')
				}else{
					s.companyListStatus = true;
				}
			},
			wxSubmintForm() {
				let s = this;
				adminGeneral.wxLogin.http({
					type:7
				}).then(res => {
					if(res.getret === 0){
						console.log(res.info.url)
						window.location.href = res.info.url;
					}else{
						Toast(res.msg)
					}
				})
			},
			switchCompanyList(){
				let s = this;
				s.$router.push('/')
			},
			countDown(type) { //发送验证码
				let s = this;
				if(s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
				if(!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
				util.countDown(this,type)
			},
			getCompany() { /*查询公司*/
				let s = this;
				adminGeneral.codeQueryCompany.http({
					"condition": {
						registcode: util.getCaption(window.location.href, '?').substring(util.getCaption(window.location.href, '?').indexOf("=")+1)
					}
				}).then(res => {
					if(res.getret === 0){
						adminGeneral.joinCompany.http({/*加入公司*/
							"companyid": res.data.companyid,
						}).then(res => {
							if(res.getret === 0){
								s.$store.commit('setCompanyid',res.data.companyid)
								s.$router.push('/')
							}else{
								Toast(res.msg)
							}
						})
					}else{
						Toast(res.msg)
					}
				})
			}
		}
	}
</script>
<style scoped>
	.login {
		padding: 60px 48px 0 48px;
		left: 0;
		top: 0;
		z-index: 2;
	}
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
</style>
